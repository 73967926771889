.sidebar {
  width: 20%;
  background-color: #f5f5dc; /* Cream color */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Removed margin to eliminate space */
}

.sidebar li {
  margin: 0; /* Removed margin for closer options */
}

/* Main link styles */
.sidebar a,
.submenu-toggle {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px;
  background-color: #f5f5dc; /* Cream color */
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s; /* Added transform for clicking effect */
  width: 100%;
  box-sizing: border-box; /* Ensures equal size buttons */
}

/* 3D effect on hover */
.sidebar a:hover,
.submenu-toggle:hover {
  background-color: #2b2929;
  color: #f5f5dc; /* Text color for hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), 0 0 15px #51ed14; /* Green glow */
  transform: translateY(2px); /* Move the button slightly down */
}

/* Icon styles */
.sidebar a .icon {
  margin-right: 10px; /* Space between icon and text */
}

/* Arrow icon styles */
.submenu-icon {
  margin-left: auto; /* Pushes the icon to the right */
  transition: transform 0.3s; /* Smooth rotation for arrow */
}

/* Rotate arrow when submenu is open */
.submenu-toggle.active .submenu-icon {
  transform: rotate(180deg); /* Rotates the arrow down */
}

/* Submenu styling */
.submenu {
  list-style-type: disc; /* Bullet points for submenus */
  padding-left: 20px; /* Indent the submenu items */
  background-color: #ffffff; /* Cream color for submenu */
  border-radius: 5px;
  margin: 0; /* No margin for submenu */
  margin-top: 0;
}

.submenu li {
  margin: 0; /* No margin for submenu items */
  font-size: 0.9rem; /* Smaller font size for submenu */
}

/* Styling for submenu links */
.submenu li a {
  padding: 8px 10px; /* Less padding for submenu links */
  color: #333; /* Ensure text color is consistent */
  display: flex;
  align-items: center;
}

/* Submenu hover styles */
.submenu li a:hover {
  background-color: #2b2929; /* Dark background for submenu on hover */
  color: #f5f5dc; /* Light text color for submenu on hover */
}

/* Sub-submenu styling */
.sub-submenu {
  list-style-type: circle; /* Bullet points for sub-submenu (different from submenu) */
  padding-left: 0px; /* Indent the sub-submenu items */
  margin: 0; /* No margin for sub-submenu */
  background-color: #f5f5dc; /* Cream color for sub-submenu */
}

.sub-submenu li {
  margin: 0; /* No margin for sub-submenu items */
  font-size: 0.8rem; /* Smaller font size for sub-submenu */
}

.sub-submenu li a {
  padding: 5px 10px; /* Less padding for sub-submenu links */
  color: #333; /* Ensure text color is consistent */
}

/* Sub-submenu hover styles */
.sub-submenu li a:hover {
  background-color: #2b2929; /* Dark background for sub-submenu on hover */
  color: #f5f5dc; /* Light text color for sub-submenu on hover */
}
