.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}

.profile-container {
  position: relative;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 15px;
}

.profile-dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  border: 1px solid #090909;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
}

.profile-name, .profile-email {
  margin: 0;
  padding: 5px 0;
  background-color: blueviolet;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.logout-button:hover {
  background-color: #d32f2f;
}
