/* Contract.css */

.contract {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5; /* Light background color */
  min-height: 100vh; /* Ensure full height */
}

.contract-content {
  margin-left: 260px; /* Space from sidebar */
  flex-grow: 1; /* Allow content to grow */
  margin-top: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;

}

h3 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  margin-bottom: 15px; /* Space below the button */
}

button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

input[type="text"] {
  padding: 10px; /* Padding for input */
  margin-right: 10px; /* Space between input and button */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  width: 200px; /* Fixed width */
}

.table-container {
  position: relative; /* Position relative for absolute elements */
}

.export-buttons {
  display: flex; /* Flex layout for buttons */
  justify-content: flex-end; /* Align to the right */
  margin-bottom: 20px; /* Space below export buttons */
}

.export-button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  margin-right: 10px; /* Space between buttons */
}

.export-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

table {
  width: 75%; /* Increased width */
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  margin-left: auto; /* Center the table horizontally */
  margin-right: auto; /* Center the table horizontally */
  border-radius: 20px;
  overflow: hidden;
}


th,
td {
  border: 1px solid #ccc; /* Light gray border */
  padding: 10px; /* Padding in table cells */
  text-align: left; /* Align text to the left */
}

th {
  background-color: #007bff; /* Blue background for header */
  color: white; /* White text for header */
}

tr:nth-child(even) {
  background-color: #ffffff; /* Light gray background for even rows */
}

tr:hover {
  background-color: #e0e0e0; /* Darker gray on hover */
}

.icon {
  width: 20px; /* Adjust the size of the icon */
  height: 20px; /* Adjust the size of the icon */
}

.add-button {
  background-color: #28a745; /* Green background for add button */
}



.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white; /* White background for the popup */
  padding: 20px; /* Padding inside the popup */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow for popup */
  z-index: 1000; /* Ensure popup is above other content */
}

.edit-popup input[type="text"] {
  padding: 10px; /* Padding for input */
  margin-right: 10px; /* Space between input and button */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  width: 200px; /* Fixed width */
}

.edit-popup button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */

}

.edit-popup button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
.csv-button {
  padding: 10px 15px; /* Ensure padding is the same as the other buttons */
  height: 25px; /* Set a fixed height to match other buttons */
  display: flex; /* Center content vertically */
  align-items: center; /* Align items in the center */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
