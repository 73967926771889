.office {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5; /* Light background color */
    min-height: 100vh; /* Ensure full height */
  }
  
  .office-content {
    margin-left: 260px; /* Space from sidebar */
    flex-grow: 1; /* Allow content to grow */
    margin-top: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 15px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    margin-bottom: 15px; /* Space below the button */
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  input[type="text"],
  input[type="number"] {
    padding: 10px; /* Padding for input fields */
    margin-bottom: 10px; /* Space below input fields */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    width: 100%; /* Full width */
  }
  
  .edit-popup {
    position: fixed; /* Fixed position */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
    background-color: white; /* White background */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
    padding: 20px; /* Padding */
    z-index: 1000; /* Ensure it appears on top */
    border-radius: 5px; /* Rounded corners */
  }
  
  table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Collapse borders */
    margin-top: 20px; /* Space above the table */
  }
  
  th, td {
    padding: 10px; /* Padding for table cells */
    text-align: left; /* Align text to the left */
    border-bottom: 1px solid #ddd; /* Light gray border below cells */
  }
  
  th {
    background-color: #f2f2f2; /* Light gray background for headers */
  }
  
  .icon {
    width: 20px; /* Set icon width */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  