.payment-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-popup {
  display: flex; /* Use flexbox for 60-40 layout */
  width: 80%; /* Set a width for the popup */
  max-width: 800px; /* Optional: Max width for the popup */
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.image-section {
  flex: 6; /* 60% of the width */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.payment-image {
  max-width: 100%; /* Ensure the image doesn't overflow */
  height: auto; /* Maintain aspect ratio */
}

.options-section {
  flex: 4; /* 40% of the width */
  padding: 20px; /* Add some padding */
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  justify-content: center; /* Center buttons vertically */
}

.options-section h2 {
  margin-bottom: 20px; /* Space below the title */
}

.options-section button {
  margin: 10px 0; /* Space between buttons */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.options-section .close-button {
  background-color: #ff6b6b; /* Red for close button */
}

.options-section button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
