body {
    margin: 0; /* Reset margin */
    font-family: Arial, sans-serif; /* Consistent font for all pages */
  }
  
  .billing-details {
    display: flex;
    margin-top: 60px; /* Space below the fixed header */
    height: calc(100vh - 60px); /* Adjust height to accommodate fixed header */
  }
  
  .sidebar {
    width: 200px; /* Fixed width for the sidebar */
    background-color: #343a40; /* Dark background for sidebar */
    color: white; /* White text color */
    position: fixed; /* Fix position */
    height: 100%; /* Full height */
    padding-top: 60px; /* Space for header */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    padding: 20px; /* Add some padding */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .billing-content {
    flex: 1;
    padding: 20px; /* Padding around content */
    margin-left: 220px; /* Ensure content does not overlap with sidebar */
    background-color: #f4f4f9; /* Light background for better readability */
    border-radius: 8px; /* Consistent rounding with other sections */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff; /* Primary color */
    color: white;
    padding: 10px 20px; /* Added horizontal padding */
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 20px;
    position: fixed; /* Make the header sticky */
    top: 0; /* Stick to the top */
    left: 0; /* Align header with sidebar */
    right: 0; /* Stretch header to the right */
    z-index: 10; /* Ensure it stays above other content */
  }
  
  .logout-button {
    background-color: #ff6b6b; /* Danger color for logout */
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .logout-button:hover {
    background-color: #e63946; /* Darker shade on hover */
  }
  
  .billing-sections {
    display: flex;
    flex-direction: column; /* Stack sections vertically */
    gap: 20px; /* Space between sections */
  }
  
  .billing-summary,
  .payment-history,
  .graph-section,
  .pay-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-history table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .payment-history th,
  .payment-history td {
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Subtle border for clarity */
  }
  
  .graph-placeholder {
    height: 200px;
    background-color: #e0e0e0; /* Light gray for placeholder */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #bbb; /* Dashed border for distinction */
    border-radius: 8px; /* Rounded corners */
  }
  
  .btn-pay-now {
    background-color: #28a745; /* Success color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .btn-pay-now:hover {
    background-color: #218838; /* Darker shade on hover */
  }
  
  .payment-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Overlay background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .payment-popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: 300px; /* Minimum width for popup */
  }
  
  .payment-popup button {
    margin: 10px 0;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Primary color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .payment-popup .close-button {
    background-color: #ff6b6b; /* Danger color for close button */
  }
  
  .payment-popup .close-button:hover {
    background-color: #e63946; /* Darker shade on hover */
  }
  
  .graph-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f9; /* Light background for consistency */
    border-radius: 8px; /* Consistent rounding */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .graph-section {
    margin-top:10px; /* Add margin to space from previous elements */
  }
  