.booking-container {
    width: calc(100% - 350px); /* Leave space for the sidebar */
    margin-left: 280px; /* Sidebar width */
    margin-top: 80px; /* Header height */
    padding: 20px;
  }
  
  .dropdown {
    cursor: pointer;
    display: flex;

    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  
  .dropdown-label {
    font-size: 16px;
  }
  
  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 3px;
    display: inline-block;
    transform: rotate(45deg); /* Right-pointing arrow */
    transition: transform 0.3s ease; /* Smooth transition */
  }
  
  .arrow.down {
    transform: rotate(135deg); /* Downward arrow */
  }
  
  .arrow.up {
    transform: rotate(-45deg); /* Upward arrow */
  }
  
    
  .dropdown-content {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #9d8f8f;
  }
  
  .booking-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .booking-table th, .booking-table td {
    padding: 12px;
    border: 1px solid #796b6b;
    text-align: left;
  }
  
  .booking-table th {
    background-color: #a23333;
  }
  
  .booking-item:hover {
    background-color: #f9f9f9;
  }
  
  .form-toggle {
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .plus-sign {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .booking-form {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .booking-form div {
    margin-bottom: 10px;
  }
  
  .booking-form input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .booking-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-form button:hover {
    background-color: #0056b3;
  }
  