/* Page container styling */
.login-page {
    display: flex;
    height: 100vh;
  }
  
  /* Left section: 40% width for the background image */
  .left-section {
    width: 60%;
    background-image: url('https://ilead.net.in/wp-content/uploads/2023/04/shutterstock_743951458-2.jpg'); /* Replace with your image */
    background-size: cover;
    background-position: center;
  }
  
  /* Right section: 60% for the login container */
  .right-section {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1e0; /* Cream color */
  }
  
  /* Login container styling */
  .login-container {
    text-align: center;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 300px
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    border-radius: 10;
  }
  
  /* Buttons */
  .login-buttons {
    display: flex;
    flex-direction: column;
    
  
  }
  
  .btn-primary {
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 5%;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #51ed14;
  }
  
  
  /* Admin Popup */
  .admin-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 5%;
  }
  
  .admin-login-form input {
    display: block;
    width: 100%;

    margin: 7px 0;
    padding: 9px;
    border-radius: 5%;
  }
  
  .close-popup {
    background-color: #333;
    color: white;
    border: none;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .close-popup:hover {
    background-color: #555;
  }
  /* Admin Popup */
.admin-popup,
.staff-popup,
.user-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Popup content styling */
.popup-content {
    width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 5%;
}

/* Staff Popup Specific Styling */
.staff-popup .popup-content {
  border: 2px solid #007bff; /* Blue border for staff popup */
  background-color: #e7f1ff; /* Light blue background */
}

/* User Popup Specific Styling */
.user-popup .popup-content {
  border: 2px solid #28a745; /* Green border for user popup */
  background-color: #e6ffe6; /* Light green background */
}

/* Additional styles for the popup content */
.popup-content h3 {
  margin-bottom: 20px;
  font-size: 20px; /* Adjust the font size for the popup title */
}

.popup-content input {
  border: 1px solid #ccc; /* Standard input border */
}

.popup-content input:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow effect on focus */
}

.close-popup {
  background-color: #dc3545; /* Red background for close button */
}

.close-popup:hover {
  background-color: #c82333; /* Darker red on hover */
}


.admin-login-form input,
.staff-login-form input,
.user-login-form input {
    display: block;
    width: 100%;
    margin: 7px 0;
    padding: 9px;
    border-radius: 5%;
}

  