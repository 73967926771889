body {
  margin: 1; /* Reset margin */
  font-family: Arial, sans-serif; /* Consistent font for all pages */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Column layout for body */
  height: 100vh; /* Full viewport height */
}

.dashboard-header {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between the title and logout button */
  align-items: center; /* Center vertically */
  background-color: #0c0e10; /* Blue background */
  color: white; /* White text color */
  padding: 20px; /* Padding around the content */
  height: 60px; /* Set a fixed height */
  width: 100%; /* Full width of the page */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
}

.assets {
  display: flex; /* Change to flex for side-by-side layout */
  flex: 1; /* Take remaining space */
  margin-left: 130px;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent overflow of content */
}

.sidebar {
  flex: 0 0 220px; /* Fixed width for the sidebar */
  background-color: #fff; /* White background for sidebar */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for sidebar */
  padding: 20px; /* Padding for the sidebar */
}

.assets-content {
  flex: 1; /* Allow the content area to take remaining space */
  padding: 0px; /* Space around assets */
  overflow-y: auto; /* Allow scrolling for assets */
  margin-left: 100px; /* Space between sidebar and content */
}

.assets-header {
  margin-bottom: 20px;
}

.assets-header h2 {
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.assets-list {
  display: flex;
  flex: 1;

  flex-wrap: wrap; /* Allow wrapping */
  gap: 20px; /* Space between assets */
  margin-top: 80px;
  margin-left: 100px;
}

.asset {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  width: 220px; /* Fixed width */
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.asset:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.asset h3 {
  margin-bottom: 10px; /* Space below asset name */
  font-size: 1.2em; /* Slightly larger font size for asset names */
  color: #333; /* Darker color for better readability */
}

.notification-panel {
  margin-top: 20px;
  padding: 15px;
  background-color: #e1f5fe;
  border-radius: 5px;
  border: 1px solid #81d4fa; /* Light blue border */
}

.notification-message {
  margin-top: 10px;
  color: #388e3c; /* Green color for success messages */
  font-weight: bold;
}

.add-asset {
  margin-top: 20px;
  padding: 15px;
  background-color: #e1f5fe; /* Light blue background for add asset */
  border-radius: 5px;
  border: 1px solid #81d4fa; /* Light blue border */
}

.in-repair {
  border: 2px solid #f44336; /* Thicker red border for assets in repair */
  background-color: #ffebee; /* Light red background for emphasis */
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #1976d2; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1565c0; /* Darker blue on hover */
}
