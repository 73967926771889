/* Breadcrumb.css */
/* Breadcrumb.css */
.breadcrumb {
    margin: 20px 0;
    margin-top: 300px;
  }
  
  .breadcrumb ul {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .breadcrumb li {
    margin: 0 5px;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb span {
    margin: 0 5px;
  }
  

.breadcrumb {
    margin: 20px 0;
    font-size: 16px;
    margin-right: 100px;
  }
  
  .breadcrumb ul {
    list-style: none;
    padding: 0;
  }
  
  .breadcrumb li {
    display: inline;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #0c0c0d; /* Link color */
  }
  
  .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  .breadcrumb span {
    margin: 0 5px;
  }
  