.home-page {
    font-family: Arial, sans-serif;
    text-align: center;
    overflow: hidden;
  }
  
  .hero-section {
    background: #f5f5f5;
    padding: 50px 20px;
  }
  
  .hero-section h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .buttons {
    margin-top: 20px;
  }
  
  .btn {
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .moving-text {
    animation: slide 10s infinite linear;
    font-size: 20px;
    background: #eee;
    padding: 10px;
    position: relative;
  }
  
  @keyframes slide {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  
  footer {
    margin-top: 50px;
    padding: 20px;
    background: #333;
    color: white;
  }
  