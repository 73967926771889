/* ConferenceRooms.css */

.conference-rooms {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5; /* Light background color */
    min-height: 100vh; /* Ensure full height */
  }
  
  .conference-rooms-content {
    margin-left: 260px; /* Space from sidebar */
    flex-grow: 1; /* Allow content to grow */
    margin-top: 20px;

  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  .conference-rooms, h3{
    margin-left: 00px;
  }

  
  .search-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="number"] {
    padding: 10px; /* Padding for input */
    margin-right: 10px; /* Space between input and button */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    width: 200px; /* Fixed width */
  }
  
  button {
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 15px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  table {
    width: 75%; /* Increased width */
    border-collapse: collapse;
    margin: 20px auto; /* Center the table */
  }
  
  th,
  td {
    border: 1px solid #ccc; /* Light gray border */
    padding: 10px; /* Padding in table cells */
    text-align: left; /* Align text to the left */
  }
  
  th {
    background-color: #007bff; /* Blue background for header */
    color: white; /* White text for header */
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray background for even rows */
  }
  
  .edit-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white; /* White background for the popup */
    padding: 20px; /* Padding inside the popup */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow for popup */
    z-index: 1000; /* Ensure popup is above other content */
  }
  