.customer-form-container {
    width: 100%;
    max-width: 1000px; /* Set a max width for the form */
    margin: auto;
    margin-left: 350px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 200px;
  }
  
  .dropdown-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .customer-form {
    display: flex;
    flex-direction: column;
  }
  
  .customer-form div {
    margin-bottom: 10px;
  }
  
  .customer-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .submit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  