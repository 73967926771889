/* Navbar.css */
.navbar {
    display: flex;
    position: fixed;
    margin-top: 0px;
    z-index: 10%;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    padding: 15px 20px;
    color: white;
  }
  
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
    transition: color 0.3s ease;
  }
  
  .navbar-links a:hover {
    color: #ffdd57;
  }
  