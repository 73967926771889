.gallery {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 400px;
  }
  
  .gallery-image {
    flex: 0 0 auto;
    width: 33.33%;
    height: 85%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  