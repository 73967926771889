.employee-management {
    display: flex; /* Flex layout for sidebar and content */
    padding: 20px;
    background-color: #f4f4f4; /* Light background */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
  }
  
  .employee-content {
    flex: 1; /* Allow content area to take remaining space */
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack header and content vertically */
  }
  
  .employee-header {
    display: flex;
    justify-content: space-between; /* Aligns header items */
    align-items: center; /* Centers items vertically */
    padding: 20px;
    background-color: #007BFF; /* Header background color */
    color: white; /* Header text color */
    border-radius: 8px; /* Rounded corners for header */
    margin-bottom: 20px; /* Space below header */
  }
  
  .employee-header h1 {
    margin: 0; /* Removes default margin */
  }
  
  .employee-table-section,
  .add-employee-section,
  .employee-graph-section {
    margin-bottom: 20px; /* Space between sections */
    padding: 20px; /* Padding for content */
    background-color: white; /* White background for sections */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
  }
  
  .employee-table {
    width: 100%;
    border-collapse: collapse; /* Remove spacing between table cells */
  }
  
  .employee-table th,
  .employee-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Light bottom border */
  }
  
  .employee-table th {
    background-color: #007BFF; /* Header background color */
    color: white; /* Header text color */
  }
  
  .employee-table tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }
  
  .add-employee-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between form elements */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc; /* Light border for input fields */
    border-radius: 5px;
    font-size: 14px;
  }
  
  .btn-primary {
    padding: 10px 15px;
  }