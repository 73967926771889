.dashboard-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.sidebar {
  width: 20%; /* 20% of the viewport width */
  background-color: #ffffff; /* Cream color */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Adjust sidebar styling as needed */
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh; /* 10% of viewport height */
  background-color: #ffffff; /* Change as needed */
  position: fixed; /* Fixed position */
  width: 100%; /* Full width */
  top: 0; /* Aligns it to the top */
  z-index: 1000; /* Keeps it above other content */
}

.main-content {
  flex: 1; /* Takes the remaining space */
  padding: 20px;
  margin-top: 0vh; /* Push down content to below header */
  margin-left: 15%; /* Aligns content next to sidebar */
  height: calc(100vh - 5vh); /* Adjust height to fit below header */
  width: calc(100vh - 10vh); /* Adjust weidth to fit below header */
  overflow-y: auto; /* Allows scrolling if content overflows */
}


.logout-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.analysis-section {
  margin-bottom: 20px;
}

.analysis-cards {
  display: flex;
  gap: 20px;
}

.card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.charts-section {
  display: flex;
  justify-content: space-between; /* Space between charts */
  margin-bottom: 20px;
  gap: 20px; /* Add some space between charts */
}

.bar-chart-container {
  width: 23%; /* Each chart takes up approximately 23% to fit 4 in one line */
}

.meeting-section {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.meeting-bucket ul {
  list-style: none;
  padding: 0;
}

.meeting-request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.accept-button,
.decline-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.decline-button {
  background-color: #d32f2f;
}

input[type="text"] {
  width: calc(100% - 120px);
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logout-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.analysis-section {
  margin-bottom: 20px;
}

.analysis-cards {
  display: flex;
  gap: 20px;
}

.card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.charts-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.bar-chart-container {
  width: 45%; /* Each chart takes up 45% of the row, ensuring two charts per row */
  margin-bottom: 20px; /* Space between rows */
}

.meeting-section {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.meeting-bucket ul {
  list-style: none;
  padding: 0;
}

.meeting-request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.accept-button,
.decline-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.decline-button {
  background-color: #d32f2f;
}

input[type="text"] {
  width: calc(100% - 120px);
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}













.meeting-request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.meeting-request-item:hover {
  background-color: #ffe4b5; /* Light cream background on hover */
}



.accept-button:hover {
  background-color: #4caf50; /* Darker green on hover */
}

.decline-button {
  background-color: #f08080; /* Light red */
}

.decline-button:hover {
  background-color: #ff4c4c; /* Darker red on hover */
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  margin-right: 10px;
}

button {
  background-color: #87cefa; /* Light blue */
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #00bfff; /* Darker blue on hover */
}





.decline-button {
  background-color: #f44336; /* Red */
  color: white;
}


.meeting-section {
  width: 500px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.meeting-bucket {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.call-request input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}
.meeting-request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.accept-button, .decline-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button {
  background-color: #4CAF50; /* Green */
  color: white;
}

.decline-button {
  background-color: #f44336; /* Red */
  color: white;
}


.call-request button {
  width: 100%;
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.call-request button:hover {
  background-color: #0056b3;
}





.sidebar {
  width: 15%; /* 20% width for sidebar */
  background-color: #ffffff; /* Same cream color */
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #333;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  display: list-item;
  margin-top: 40px;
}

.sidebar li {
  margin: 15px 0;
  flex-wrap: wrap;
}

.sidebar a {
  display: inline-block; /* Make the links behave like buttons */
  padding:  10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: all 0.3s; /* Smooth transition for hover effects */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.sidebar a:hover {
  color: #e8d9d9; /* Change text color on hover */
  background-color: #2b2929; /* Change background color on hover */
  transform: translateY(2px); /* Move the button down slightly on hover */
}



.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Cream color */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-header h1 {
  font-size: 1.5rem;
  color: #333;
}




.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 30%; /* Adjust width based on your design */
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.logout-button {
  background-color: #f5f5dc; /* Cream color */
  color: #333; /* Text color */
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.logout-button:hover {
  background-color: #2b2929; /* Dark background on hover */
  color: #e8d9d9; /* Light text color on hover */
  transform: translateY(2px); /* Move down on hover */
}

